<template lang="pug">
el-dialog(
  custom-class='companiesList',
  :title='$t("copyReportTemplateTranslater.title")',
  :visible.sync='dialogVisible',
  :modalAppendToBody='false',
  @close='handleClose',
  top='30vh'
)
  //-   el-dialog(
  //-     custom-class='innerDialog',
  //-     width='40%',
  //-     top='50vh',
  //-     :visible.sync='innerVisible',
  //-     append-to-body,
  //-     :modalAppendToBody='false'
  //-   )
  //-     .p(slot='title') Вы действительно хотите восстановить <b class='boldText'> {{ current.name }} </b> current.name объект в компании <b class='boldText'> {{ currentCompany.name }} </b> ?
  //-     .flex.w-full.justify-center
  //-       skif-button.cursor-pointer(@click='save') Сохранить
  p.ml-5.text {{ $t("copyReportTemplateTranslater.selectCompany") }}
  p.ml-5.text.mb-4 {{ $t("copyReportTemplateTranslater.copy") }}
  skif-search.mx-4.mt-2(v-model='filterString' :placeholder='$t("search")' @searching="searching")
  .copanies-container.mt-4.overflow-y-auto
    ul.pl-4.mr-2
      li.cursor-pointer.p-3.hover_bg-hoverrow(
        v-for='(company, index) in filterData',
        :key='index',
        @click='toggle(index), chooseCompany(company)',
        :class='{ "company-active": index === isRowClicked }'
      ) {{ company.name }}
  span.dialog-footer.flex.justify-end(slot='footer')
    p.cursor-pointer.cancelBtn.mr-8(
      type='primary',
      @click='dialogVisible = false',
      Cancel
    ) {{ $t("copyReportTemplateTranslater.cancel") }}
    p.cursor-pointer.saveBtn(@click='save') {{ $t("copyReportTemplateTranslater.save") }}
</template>

<script>
import { mapState } from 'vuex'
import { objectsApi } from '@/api'

export default {
  props: ['current'],
  data() {
    return {
      dialogVisible: true,
      hideDialog: false,
      filterString: '',
      myCompanies: [],
      isRowClicked: '',
      innerVisible: false,
      currentCompany: '',
      formData: {
        type: 'report_templates',
        ids: '',
        to_company_id: ''
      }
    }
  },
  computed: {
    ...mapState('login', {
      companies: (state) => state.me.companies
    }),
    filterData() {
      return this.myCompanies.filter((company) =>
        JSON.stringify(company)
          .toLowerCase()
          .includes(this.filterString.toLowerCase())
      )
    }
  },
  watch: {
    hideDialog(val) {
      // if (val) {
      //   let dialog = document.getElementsByClassName('.v-modal')
      //   dialog.style.display = 'none'
      // }
    }
  },
  methods: {
    searching(val) {
      this.filterString = val
    },
    async save() {
      this.formData.to_company_id = this.currentCompany.id
      this.formData.ids = [this.current.id]
      const { formData } = this

      await objectsApi.copyUnit(
        { formData },
        (response) => {
          this.hideDialog = true
          this.dialogVisible = false
          this.$notify({
            title: this.$t('attention'),
            message: this.$t('reports.success_copy_template'),
            type: 'success'
          })
        },
        (error) => {
          console.log('error', error)
          this.$showError(error.response.data.message)
        }
      )

      this.innerVisible = false
    },
    handlInnerConfirmation() {
      this.innerVisible = false
    },

    toggle(index) {
      this.isRowClicked = index
    },
    chooseCompany(company) {
      this.currentCompany = company
    },
    handleClose() {
      this.$emit('close-dialog', 'close')
    },
    handlInnerClose() {
      this.innerVisible = false
    },
    makeCopy(val) {
      this.innerVisible = true
    }
  },
  mounted() {
    this.myCompanies = this.companies
  }
}
</script>

<style lang="scss" scoped></style>
<style>
.boldText {
  font-size: 16px;
  font-weight: bolder;
}
.innerDialog .el-dialog__header {
  margin-right: 20px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.innerDialog .el-dialog__header {
  color: #5477a9;
}
.innerDialog .el-dialog__body {
  padding-bottom: 20px;
  padding-top: 10px;
}
.company-active {
  background-color: #eef5ff;
}
.copanies-container {
  height: 200px;
}
.companiesList {
  width: 335px;
}
.companiesList .el-dialog__title {
  color: #3c5e8f;
  font-weight: bold;
}
.companiesList .el-dialog__body {
  padding-top: 3px;
  padding-bottom: 3px;
  padding: 0;
}
.saveBtn {
  color: #5477a9;
  font-weight: 600;
}

.cancelBtn {
  color: #cfdbeb;
  font-weight: 600;
}
.text {
  color: #a0a0a0;
  font-weight: 600;
}
ul li {
  color: #3c5e8f;
  font-size: 14px;
  font-weight: 600;
}
</style>
